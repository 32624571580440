import React, { useState } from "react";
import * as Sentry from "@sentry/react";

import {
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  useToast,
  Image,
} from "@chakra-ui/react";

import MoblyzeApolloClient from "../services/MoblyzeApolloClient";
import FormModal from "./FormModal";

import { CREATE_AGENCY_MUTATION, UPDATE_AGENCY_MUTATION } from "../graphql/agencies";
import { AddIcon } from "@chakra-ui/icons";
import { ImageFilePicker } from "./Filepicker";

function AgencyModal({
  isOpen,
  onOpen,
  onClose,
  agency,
  refetchAgencies,
}) {
  const toast = useToast();

  const [name, setName] = useState("");
  const [agencyType, setAgencyType] = useState("");
  const [logo, setLogo] = useState("");

  const resetForm = () => {
    setName("");
    setAgencyType("");
    setLogo("");
  };

  // Reset state when modal is closed
  React.useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (agency) {
      setName(agency.name || "");
      setAgencyType(agency.type || "");
    }
  }, [agency]); // Run this effect whenever the agency prop changes


  const onSubmit = () => {
    if (!name) {
      toast({
        title: "Please fill out all fields",
        description: "Please fill out all fields to continue.",
        status: "error",
        duration: 5000,
      });
      return;
    }

    MoblyzeApolloClient.mutate({
      mutation: agency ? UPDATE_AGENCY_MUTATION : CREATE_AGENCY_MUTATION,
      variables: {
        name,
        agencyType,
        ...(logo && { logo }),
        ...(agency && { slugId: agency.slugId })
      },
    })
      .then((res) => {
        // Trigger refetch to reload agency list
        if (refetchAgencies) {
          console.log('refetch...')
          refetchAgencies();
        }

        toast({
          title: agency ? "Agency updated successfully!" : "Congratulations on creating a new Agency!",
          description: agency ? `Agency '${name}' has been updated.` : `Agency '${name}' has been created.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        isOpen = false; // Close modal or form
      })
      .catch((err) => {
        Sentry.captureException(err);
        toast({
          title: agency ? "Error updating the Agency" : "Error creating the Agency",
          description: err?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });

  };

  return (
    <FormModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      submitTitle="Submit"
      title="Create a new Agency"
      dark={true}
    >
      <FormControl>
        <FormLabel htmlFor="name" fontSize="xs" color="white">
          Name
        </FormLabel>

        <Input
          id="name"
          placeholder="Name"
          type="text"
          variant="light"
          bg="#2D3748"
          color="white"
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
          mb="4"
        />

        <FormLabel htmlFor="type" fontSize="xs" color="white">
          Type
        </FormLabel>
        <Select
          id="type"
          variant="light"
          bg="#2D3748"
          onChange={(e) => setAgencyType(e.target.value)}
          sx={{
            '> option': {
              background: 'transparent',
            },
          }}
        >
          <option value='corporate' selected={agencyType === 'corporate'}>Corporate</option>
          <option value='agency' selected={agencyType === 'agency' || !agencyType}>Agency (Moblyze)</option>
        </Select>

        <FormLabel htmlFor="logoUrl" fontSize="xs" color="white" mt="4">
          Logo
        </FormLabel>

        {agency?.logoUrl && (
          <Image src={agency?.logoUrl} w={100} h={100} />
        )}

        <ImageFilePicker
          onChange={(file) => setLogo(file)}
          name="logoUrl"
          setImageValue={setLogo}
        />

      </FormControl>
    </FormModal>
  );
}

export function AgencyForm({refetchAgencies, agency = null}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <AgencyModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        agency={agency}
        refetchAgencies={refetchAgencies}
      />

      <Button
        leftIcon={agency ? null : <AddIcon />}
        colorScheme={ agency ? 'white' : 'gray' }
        variant={agency ? 'outline' : 'solid'}
        onClick={onOpen}
        size={agency ? 'xs' : null}
      >
        {agency ? 'Edit' : 'Create'}
      </Button>
    </>
  );
}
