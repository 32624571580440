import React, { useState } from "react";
import { Button } from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
} from "@chakra-ui/react";

import colors from "../helpers/colors";

export default function FormModal({
  isOpen,
  onOpen,
  onClose,
  title,
  submitTitle,
  onSubmit,
  children,
  dark
}) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={dark ? '#1A202C' : null}>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody pb="4">
            <Flex direction="column">
              <Text
                fontSize="xl"
                fontWeight="600"
                textAlign={"left"}
                mb="3"
                mt="0"
                color={dark ? 'white' : 'black'}
              >
                {title}
              </Text>
              {children}
              <Button onClick={onSubmit} mb="4" mt="4" colorScheme={dark ? 'gray' : null} bg={dark ? 'white' : null} color={dark ? 'black' : null}>
                {submitTitle}
              </Button>
              <Button color={dark ? 'ghost' : 'black'} variant={dark ? 'outline' : 'ghost'} onClick={onClose}>
                Cancel
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
