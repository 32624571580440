import React from "react";

import WithAppLayout from "../../components/WithAppLayout";

import WithQuery from "../../components/WithQuery";
import { AGENCIES_QUERY } from "../../graphql/agencies";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import PageTitle from "../../components/PageTitle";

import {
  Box,
  Button,
  Flex,
  Image,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  useToast,
} from "@chakra-ui/react";
import MoblyzeApolloClient from "../../services/MoblyzeApolloClient";
import { UPDATE_CURRENT_USER_AGENCY_MUTATION } from "../../graphql/users";
import { AgencyForm } from "../../components/AgencyForm";
import { useNavigate } from "react-router-dom";

function AgenciesPageInner({
  data,
  error,
  loading,
  refetch,
  currentUser,
}) {

  const Card = ({ agency, isCurrent}) => (
    <Box
      border="1px solid"
      borderColor={isCurrent ? 'teal.400' : 'gray.700'}
      borderRadius="md"
      p={2}
      bg="gray.900"
      boxShadow="lg"
      display="flex"
      overflow="hidden"
      justifyContent="space-between"
      alignItems="center"
    >
      <div>
        <Image src={agency?.logoUrl} alt={`${agency?.name} logo`} boxSize="50px" mx="auto" mr={2} />
      </div>
      <div style={{ flex: 1, marginRight: "4px" }}>
        <Text fontSize="md" fontWeight="bold" color="white" mb={1}>
          {agency?.name}
        </Text>
        <Tag size="sm" colorScheme={agency?.type === 'corporate' ? 'gray' : 'teal' } textTransform={'capitalize'}>
          {agency?.type || 'Agency'}
        </Tag>
      </div>
      <Flex>

        <AgencyForm refetchAgencies={refetch} agency={agency} />

        {!isCurrent && (
          <Button ml="2" variant="outline" colorScheme="white" size="xs" borderColor="#E2E8F0" onClick={() => onSwitchAgencyButtonPress(agency.slugId)}>
            Select
          </Button>
        )}
      </Flex>
    </Box>
  );

  const toast = useToast();

  if (loading) {
    return <LoadingIndicator />;
  }

  const onSwitchAgencyButtonPress = async (agencySlugId) => {
    try {
      const r = await MoblyzeApolloClient.mutate({
        mutation: UPDATE_CURRENT_USER_AGENCY_MUTATION,
        variables: { agencySlugId },
      });
      console.log("Update user response", r);

      toast({
        title: "Your profile has been updated",
        status: "success",
      });
      window.location.reload();
    } catch (e) {
      console.log("Update user error", e);
      alert("Error", "There was an error updating your profile.");
    }
  };

  return (
    <div>
      <Stack spacing="1" flex="1">
        <Stack
          direction={{
            base: "column",
            md: "row",
          }}
          justify="space-between"
          mb={8}
        >
          <PageTitle title={`Active Agency`} />
        </Stack>

        <Card
          agency={data.agencies.find((agency) => agency.slugId === currentUser?.recruiterAgencySlugId)}
          isCurrent={true}
        />

        <Stack
          direction={{
            base: "column",
            md: "row",
          }}
          justify="space-between"
          mb={8}
          mt={8}
        >
          <PageTitle title={`Agencies`} />

          <AgencyForm refetchAgencies={refetch} />

        </Stack>
        <SimpleGrid spacing={4}>
            {data.agencies
              .filter(agency => agency?.slugId !== currentUser?.agency?.slugId)
              .map((agency, index) => (

                  <Card
                    key={index}
                    agency={agency}
                  />

              ))
            }

        </SimpleGrid>

      </Stack>
    </div>
  );
}

function PageComponent({ children, currentUser }) {
  if (!currentUser?.isOwner) {
    return null;
  }

  return WithQuery(AgenciesPageInner, AGENCIES_QUERY, null, {
    currentUser,
    children
  })();
}

export default WithAppLayout(PageComponent);
