import {
  Box,
  Divider,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Image,
  Text,
} from "@chakra-ui/react";
import { ReactComponent as Information } from "../assets/icons/information.svg";
import { ReactComponent as Pin } from "../assets/icons/pin.svg";
import { ReactComponent as Calendar } from "../assets/icons/calendar.svg";
import { ReactComponent as Search } from "../assets/icons/search.svg";
import { ReactComponent as Briefcase } from "../assets/icons/briefcase.svg";
import { ReactComponent as Duration } from "../assets/icons/duration.svg";
import { ReactComponent as Lifebuoy } from "../assets/icons/lifebuoy.svg";
import { ReactComponent as Wifi } from "../assets/icons/wifi.svg";
import { ReactComponent as NoSmoking } from "../assets/icons/no-smoking.svg";
import { ReactComponent as People } from "../assets/icons/people.svg";
import moment from "moment";
import { FaDollarSign } from "react-icons/fa";
import { formatPayFrequency } from "../helpers/strings";

function JobDetails({ isOpen, onClose, moblyzeJob, isClient }) {
  return (
    <Drawer
      variant="secondary"
      size="full"
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent overflow="auto" padding="60px 50px" background="gray.900">
        <DrawerCloseButton
          fontSize={12}
          size="sm"
          position="absolute"
          left="18px"
          top="18px"
        />
        <Flex direction="column" gap="14px">
          {isClient && (
            <Flex justifyContent="flex-end" gap="12px" alignItems="center">
              <Icon fontSize={20} as={Information} />
              <Text fontSize="md" lineHeight="6" fontWeight="normal">
                Contact your recruiter to edit job details.
              </Text>
            </Flex>
          )}
          <Flex gap="12px">
            <Flex flex={1} direction="column" gap="20px">
              <Box>
                <Text fontSize="lg" lineHeight="7" fontWeight="semibold">
                  {moblyzeJob?.category}
                </Text>
                <Text fontSize="md" lineHeight="6" fontWeight="normal">
                  {moblyzeJob?.title}
                </Text>
              </Box>
              <Flex align="center" gap="8px">
                <Icon fontSize={20} as={Pin} />
                <Text fontSize="md" lineHeight="6" fontWeight="normal">
                  {moblyzeJob?.location}
                </Text>
              </Flex>
              {/* MOCK */}
              {/* <Flex align='center' gap='8px'>
                <Icon fontSize={20} as={Briefcase} />
                <Text fontSize='md' lineHeight='6' fontWeight='normal'>Full-time</Text>
              </Flex> */}
              {/* MOCK */}
              <Flex align="center" gap="8px">
                <Icon fontSize={20} as={Calendar} />
                Starting&nbsp;
                {moment(moblyzeJob?.startDate).format("MMMM Do, YYYY")}
              </Flex>
              <Flex align="center" gap="8px">
                <Icon fontSize={20} as={Duration} />
                <Text fontSize="md" lineHeight="6" fontWeight="normal">
                  {moblyzeJob?.duration}
                </Text>
              </Flex>
              <Flex align="center" gap="8px">
                <Icon fontSize={20} as={Search} />
                <Text fontSize="md" lineHeight="6" fontWeight="normal">
                  {moblyzeJob?.numOpenings} Opening
                  {moblyzeJob?.numOpenings !== 1 && "s"}
                </Text>
              </Flex>
              {moblyzeJob?.payRateValue && moblyzeJob?.payRateValue !== "" && (
                <Flex align="center" gap="8px">
                  <Icon fontSize={20} as={FaDollarSign} />
                  <Text fontSize="md" lineHeight="6" fontWeight="normal">
                    {`${moblyzeJob?.payRateValue} ${
                      moblyzeJob?.payCurrency
                    }/${formatPayFrequency(moblyzeJob?.payFrequency)}`}
                  </Text>
                </Flex>
              )}
            </Flex>
            <Flex flex={1}>
              <Image
                h={272}
                w={452}
                borderRadius={6}
                src={moblyzeJob?.coverImageUrl}
              />
            </Flex>
          </Flex>
          <Divider />
          <Text fontSize="md" lineHeight="6" fontWeight="semibold">
            Requirements
          </Text>
          <Flex flexWrap="wrap" gap="12px">
            {moblyzeJob?.roles?.map((role) => (
              <Box
                key={role.id}
                padding="10px"
                borderRadius={20}
                fontSize="14px"
                fontWeight="500"
                lineHeight="20px"
                border="1px solid #FFFFFF"
              >
                {role.name}
              </Box>
            ))}
          </Flex>
          <Text
            fontSize="md"
            lineHeight="6"
            fontWeight="normal"
            whiteSpace="pre-line"
          >
            {moblyzeJob?.requirementsDescription}
          </Text>
          <Divider />
          <Text fontSize="md" lineHeight="6" fontWeight="semibold">
            Key Responsibilities
          </Text>
          <Text
            fontSize="md"
            lineHeight="6"
            fontWeight="normal"
            whiteSpace="pre-line"
          >
            {moblyzeJob?.responsibilitiesDescription}
          </Text>
          <Divider />

          <Text fontSize="md" lineHeight="6" fontWeight="semibold">
            {moblyzeJob?.vessel?.name} Details
          </Text>
          {/* MOCK */}
          {/* <Flex align='center' gap='8px'>
            <Icon fontSize={20} as={Lifebuoy} />
            <Text fontSize='md' lineHeight='6' fontWeight='normal'>
              1994 (retrofitted, 2015)
            </Text>
          </Flex> */}
          {/* MOCK */}
          {/* MOCK */}
          <Flex align="center" gap="8px">
            <Icon fontSize={20} as={Pin} />
            <Text fontSize="md" lineHeight="6" fontWeight="normal">
              {moblyzeJob?.location}
            </Text>
          </Flex>
          {/* MOCK */}
          <Flex align="center" gap="8px">
            <Icon fontSize={20} as={Wifi} />
            {moblyzeJob?.vessel?.wifiSpeedDescription}
          </Flex>
          <Flex align="center" gap="8px">
            <Icon fontSize={20} as={NoSmoking} />
            <Text fontSize="md" lineHeight="6" fontWeight="normal">
              {moblyzeJob?.vessel?.isSmoking}
            </Text>
          </Flex>
          <Flex align="center" gap="8px">
            <Icon fontSize={20} as={People} />
            <Text fontSize="md" lineHeight="6" fontWeight="normal">
              {moblyzeJob?.vessel?.totalCrew} Total Crew
            </Text>
          </Flex>
          <Divider />
          {/* MOCK */}
          {/* <Text fontSize='md' lineHeight='6' fontWeight='normal'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </Text> */}
          {/* MOCK */}
        </Flex>
      </DrawerContent>
    </Drawer>
  );
}

export default JobDetails;
